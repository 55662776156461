/*
  样式规范表
*/
$font50:  50px;
$font36:  36px;
$font32:  32px;
$font30:  30px;
$font28:  28px;
$font26:  26px;
$font24:  24px;
$font22:  22px;
$font20:  20px;
$font18:  18px;
$font16:  16px;
$font14:  14px;
$font12:  12px;
//主题色
$themeColor297:  #2979FF;
// 字体颜色
$fontColor203:  #203152;
$fontColorF55:  #F55F4E;
$fontColorFFF:  #FFFFFF;

// 背景色
$bgColorF5F:  #F5F5F5;
$bgColorFFF:  #FFFFFF;
$bgColor06C:  #06CC66;

// 边框颜色
$borderColor203:  rgba(32, 49, 82, 0.12);
// 常规字体
$fontFamilyPingFangRegular: NotoSansSC-Regular, NotoSansTC-Regular;
$fontFamilyPingFangBold: NotoSansSC-Semibold, NotoSansTC-Semibold;
@mixin flex($direction: row, $horizontal: space-between, $vertical: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $vertical;
  justify-content: $horizontal;
}
@mixin backgroundImg($w: 0, $h: 0, $url: '', $size: contain) {
  display: inline-block;
  width: $w;
  height: $h;
  background: url($url) no-repeat center;
  background-size: $size;
}

@mixin ellipsis($line: 1) {
  word-break: break-all;
  white-space: normal;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /*! autoprefixer: on */
}

@mixin regularFontText($fs: '32px', $c: '#203152', $lh: '45px',) {
  font-size: $fs;
  font-family: NotoSansSC-Regular, NotoSansTC-Regular;
  color: $c;
  font-style: normal;
  font-weight: 400;
  line-height: $lh;
}

@mixin boldFontText($fs: '32px', $c: '#203152', $lh: '45px',) {
  font-size: $fs;
  font-family: NotoSansSC-Semibold, NotoSansTC-Semibold;
  color: $c;
  font-style: normal;
  font-weight: 700;
  line-height: $lh;
}
.error-box{
  width: 100%;
  height: 100vh;
  @include flex(column, center, center);
  img{
    width: 200px;
    height: 200px;
  }
  p{
    margin-top: 50px;
    @include regularFontText($font32,  $fontColor203, 45px);
  }
  button{
    margin-top: 40px;
    width: 80%;
    height: 96px;
    @include regularFontText($font36, $fontColorFFF, 50px);
    border-radius: 48px;
    background: $themeColor297;
  }
}