@font-face {
  font-family: 'NotoSansSC-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('https://temp-cdn.huixinguanli.cn/tob/static/media/font/NotoSansSC-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSansSC-Semibold';
  font-style: normal;
  font-weight: 700;
  src: url('https://temp-cdn.huixinguanli.cn/tob/static/media/font/NotoSansSC-Semibold.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSansTC-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('https://temp-cdn.huixinguanli.cn/tob/static/media/font/NotoSansTC-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSansTC-Semibold';
  font-style: normal;
  font-weight: 700;
  src: url('https://temp-cdn.huixinguanli.cn/tob/static/media/font/NotoSansTC-Semibold.woff2') format('woff2');
}